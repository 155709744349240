<template>
  <div class="alert-error" v-if="isVisibleMsgError">
    <div class="title-msg-error">
      <button @click="_closeMsg" class="close-button"><b-icon-x></b-icon-x></button>
      <p class="left">
        Falha ao processar requisição
      </p>
    </div>
    <div class="alert-msg-error">
      <p>
        <slot name="alert-error"></slot>
      </p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AlertError',

  data: () => ({
    isVisibleMsgError: true,
  }),

  methods: {
    _closeMsg() {
      this.isVisibleMsgError = false;
    },
  }

}
</script>

<style>
.alert-error {
  position: absolute;
  width: 541px;
  height: 81px;
  left: 492px;
  top: 43px;

  margin: 0 auto;

  background: #FFFFFF;
  border: 1px solid #DDDFE0;
  box-sizing: border-box;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}

.title-msg-error {
  position: absolute;
  width: 100%;
  height: 34px;

  background: #FCDDE2;
  border: 1px solid #FBCFD6;
  box-sizing: border-box;
}

.title-msg-error p {
  margin: 8px 10px 10px 8px;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #7D2C38;
}

.alert-msg-error {
  position: absolute;
  margin-top: 50px;
  text-align: center;
  width: 100%;

  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #6C757D;
}

.close-button {
  position: absolute;
  top: 1px;
  right: 5px;
  color: #6C757D;
  background: #FCDDE2;
  border: 0px solid;
}
</style>