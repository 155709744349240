import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'LandingPage',
    component: () => import(/* webpackChunkName: "landing-page" */ '../views/LandingPage.vue')
  },
  {
    path: '/example',
    name: 'Example',
    component: () => import(/* webpackChunkName: "landing-page" */ '../example/pages/home.vue')
  },
  {
    path: '/tray-install',
    name: 'TrayStartInstall',
    component: () => import(/* webpackChunkName: "tray-install" */ '../views/TrayStartInstall.vue')
  },
  {
    path: '/tray-finish-install',
    name: 'TrayFinishInstall',
    component: () => import(/* webpackChunkName: "tray-finish-install" */ '../views/TrayFinishInstall.vue')
  },
  {
    path: '/tiktok-install',
    name: 'TikTokInstall',
    component: () => import(/* webpackChunkName: "tiktok-install" */ '../views/TikTokInstall.vue')
  },
  {
    path: '/wizard',
    name: 'Wizard',
    props: route => ({ query: route.query.q }),
    component: () => import(/* webpackChunkName: "wizard-2" */ '../views/Wizard2.vue')
  },
  {
    path: '/pagina-inicial',
    name: 'PaginaInicial',
    props: route => ({ query: route.query.q }),
    component: () => import(/* webpackChunkName: "pagina-inicial" */ '../views/PaginaInicial.vue')
  },
  {
    path: '/campanhas',
    name: 'Campanhas',
    props: route => ({ query: route.query.q }),
    component: () => import(/* webpackChunkName: "campanhas" */ '../views/Campanhas.vue')
  },
  {
    path: '/configuracoes',
    name: 'Configuracoes',
    props: route => ({ query: route.query.q }),
    component: () => import(/* webpackChunkName: "configuracoes" */ '../views/Configuracoes.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/wizard-antigo',
    name: 'WizardAntigo',
    props: route => ({ query: route.query.q }),
    component: () => import(/* webpackChunkName: "wizard" */ '../views/Wizard.vue')
  },
  {
    path: '/success',
    name: 'ResponseSuccess',
    component: () => import(/* webpackChunkName: "success" */ '../views/ResponseSuccess.vue')
  },
  {
    path: '/error',
    name: 'ResponseError',
    component: () => import(/* webpackChunkName: "error" */ '../views/ResponseError.vue')
  },
  {
    path: '/account-created',
    name: 'AccountCreated',
    props: route => ({ query: route.query.q }),
    component: () => import(/* webpackChunkName: "account-created" */ '../views/AccountCreated.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
