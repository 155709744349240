let { hostname } = window.location;

let Config = {
    apiUrl: (() => {
        const { protocol } = window.location;

        let apiUrl = process.env.VUE_APP_API_URL || '';
        let apiPath = process.env.VUE_APP_API_PATH || '';
        let apiPort = process.env.VUE_APP_API_PORT || '';

        apiPort = apiPort === '' ? apiPort : ':' + apiPort;

        if (apiPath.charAt(0) === '/') {
            apiPath = apiPath.substr(1);
        }

        if (!apiUrl) {
            apiUrl = hostname;
        }

        return `${protocol}//${apiUrl}${apiPort}/${apiPath}`;
    })(),
}

export default Config;