import Vue from 'vue'
import axios from 'axios'
import Config from "../../config/config";

Vue.use({
    install(Vue) {
        Vue.prototype.$http = axios.create({
            // baseURL: 'https://stg-tiktok.tray.com.br/tiktok/api/v1/'
            // baseURL: 'https://d3qmdtmep7z7wk.cloudfront.net/tiktok/api/v1/'
            baseURL: Config.apiUrl
        })
    }
})